import React  from "react";
import { Link } from "react-router-dom";
import client1 from "../assets/images/web/logo/f.jpg"
import client2 from "../assets/images/web/logo/m2.jpg"
export default function TeamTwo(){
    const teamData = [
        {
            image:client2,
            name:'Amarnath Pennabadi',
            title:'Client Relationship Manager '
        },
        {
            image:client1,
            name:'Jayati Dev',
            title:'Talent Acquisition Specialist'
        },
        {
            image:client2,
            name:'Mahesh D',
            title:'Cyber Security Expert '
        },
        
        {
            image:client1,
            name:'V. Seshu',
            title:'Service Delivery Manager'
        }, 
        {
            image:client1,
            name:'Seenu Reddy',
            title:'Project Manager '
        },
    ]
    return(
        <>
        <div className="row">
            {teamData.map((item,index)=>{
                return(
                    <div className="col-lg-4 col-md-6 mt-4 pt-2" key={index}>
                        <div className="card team team-primary rounded team-two overflow-hidden shadow">
                            <div className="team-image imageTeamDesgination">
                                <img src={item.image} className="img-fluid" alt=""/>
                                {/* <div className="overlay"></div> */}
                            {/* <div className="team-content"> */}
                                <Link to="#" className="h6 text-uppercase d-block mb-0 text-dark title-dark teamTitle">{item.name}</Link>
                                <small className="text-dark title-dark teamDesgination">{item.title}</small>
                            {/* </div> */}

                            </div>
                            {/* <ul className="list-unstyled social-icon team-social mb-0">
                                <li><Link to="#" className="rounded"><FiFacebook className="fea icon-sm fea-social"/></Link></li>
                                <li className="mt-1"><Link to="#" className="rounded"><FiInstagram className="fea icon-sm fea-social"/></Link></li>
                                <li className="mt-1"><Link to="#" className="rounded"><FiTwitter className="fea icon-sm fea-social"/></Link></li>
                                <li className="mt-1"><Link to="#" className="rounded"><FiLinkedin className="fea icon-sm fea-social"/></Link></li>
                            </ul> */}
                            {/* <div className="team-content">
                                <Link to="#" className="h6 text-uppercase d-block mb-0 text-white title-dark">{item.name}</Link>
                                <small className="text-white title-dark">{item.title}</small>
                            </div> */}
                        </div>
                    </div>
                )
            })}
        </div>
        </>
    )
}