import React from "react";
import { Link } from "react-router-dom";
import bg1 from "../assets/images/web/services/it_consulting_header.jpg"
import about from "../assets/images/web/services/it_staffing_main.jpg"
import image1 from "../assets/images/web/services/service_sidebar.jpg"
import NavbarTwo from "../components/navbar/navbarTwo";
import FooterSeven from "../components/footer/footerSeven";
import ScrollTop from "../components/scrollTop";

export default function SingleService(){

    return(
        <>
        <NavbarTwo navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light"/>

        {/* <section className="bg-half-80 d-table bg-primary bg-gradient w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}> */}
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row g-0 align-items-center mt-5">
                    <div className="col-lg-8 col-md-6">
                        <div className="title-heading text-md-start text-center">
                            <h5 className="heading fw-semibold page-heading text-white title-dark">IT Staffing </h5>
                            <p className="text-white-50 mb-0">Walking is the best possible exercise</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 mt-sm-0">
                        <div className="text-md-end text-center">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/services">Services</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">IT Staffing</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-6">
                        <div className="section-title">
                            <img src={about} className="img-fluid rounded" alt=""/>

                            {/* <h4 className="mt-4">Preparing for your success with web development</h4> */}

                            <p className="text-muted mt-4">At Integra Assist, we understand the critical role adept IT staffing plays in advancing your business towards its strategic goals. Our tailored IT staffing solutions are designed to meet the unique demands of your projects, offering both supplemental and permanent staffing services.</p>
                        
                            {/* <blockquote className="text-center mx-auto blockquote"><i className="mdi mdi-format-quote-open mdi-48px text-muted opacity-2 d-block"></i> The man who comes back through the door in the wall will never be quite the same as the man who went out. <small className="d-block text-muted mt-2">- integra Template</small></blockquote> */}
                            
                            <h5 className="mt-4">Supplemental Staffing: </h5>
                            <p className="text-muted mt-4">Our service provides a cost-efficient method to augment your staff with skilled professionals for specified durations. We streamline the hiring process, liberating your internal resources from the rigorous tasks of advertising, screening, and credential verification, allowing them to concentrate on core business endeavors.</p>

                            <h5 className="mt-4">Permanent Staffing: </h5>
                            <p className="text-muted mt-4">Tackling in-house hiring can be challenging and time-consuming. Our Permanent Staffing solutions alleviate these challenges by connecting you with proficient candidates who not only excel in their fields but also align with your organizational culture and objectives.</p>

                            <p className="text-muted mt-4">At each step, our focus is on cultivating lasting relationships with our clients, ensuring a thorough understanding of your industry goals and personnel needs. This client-centric approach, combined with our dedication to excellence, empowers us to deliver staffing solutions that truly resonate with your operational requirements. Catering to a diverse clientele, from emerging small businesses to established large corporations, our IT Staffing Services are geared towards enhancing your operational efficiency and propelling your projects to success. Trust Integra Assist as your strategic ally in navigating the IT staffing realm, bolstering your project execution, and achieving remarkable outcomes with unparalleled expertise.</p>


                          
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="sidebar sticky-bar">
                            <div className="widget text-center position-relative">
                                <div className="rounded shadow bg-white">
                                    <div className="position-relative">
                                        <img src={image1} className="img-fluid rounded shadow" alt=""/>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="widget mt-4 pt-2">
                            <div className="card bg-white shadow rounded border-0">
                                    <div className="card-body">
                                        <h4 className="card-title">Get In Touch !</h4>
                                        <div className="custom-form mt-4">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                                            <input name="name" id="name" type="text" className="form-control" placeholder="Name :"/>
                                                        </div>
                                                    </div>
                
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                            <input name="email" id="email" type="email" className="form-control" placeholder="Email :"/>
                                                        </div> 
                                                    </div>
                
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Subject</label>
                                                            <input name="subject" id="subject" className="form-control" placeholder="Subject :"/>
                                                        </div>
                                                    </div>
                
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Comments <span className="text-danger">*</span></label>
                                                            <textarea name="comments" id="comments" rows="3" className="form-control" placeholder="Message :"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button className="btn btn-primary">Send Message</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterSeven/>
        <ScrollTop/>
        </>
    )
}