import React from "react";
import { Link } from "react-router-dom";
import bg1 from "../assets/images/web/career/Careers-Banner.jpg"
import about from "../assets/images/web/career/Careers-600x700.jpg"
import NavbarTwo from "../components/navbar/navbarTwo"
import FooterSeven from "../components/footer/footerSeven";
import ScrollTop from "../components/scrollTop";
import {AiOutlineCheckCircle, FiAirplay,FiArrowRight} from '../assets/icons/vander'


export default function AboutUs(){

    const aboutData = [
        {
            icon:FiAirplay,
            title:'Java Full Stack developers',
            desc:'We are actively looking for Java Full Stack developers in both the USA and India. Please send us your resume. Apply Now!! '
        },
    ]
   
    return(
        <>
        <NavbarTwo navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light"/>

        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            {/* <small className="text-white-50 mb-1 fw-medium text-uppercase mx-auto">W</small> */}
                            <h5 className="heading fw-semibold mb-0 page-heading text-white title-dark">Careers</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Career</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6">
                        <img src={about} className="img-fluid rounded shadow" alt=""/>
                    </div>

                    <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="section-title ms-lg-5">
                            <h4 className="title mb-3">Career</h4>
                            <p className="text-muted">Embark on a rewarding career journey where innovation meets  excellence. Apply to explore diverse opportunities, leverage your expertise, foster professional growth, and contribute to collaborative, transformative business solutions.</p>
                            <ul className="list-unstyled text-muted mb-0">
                                <li className="mb-0"><span className="text-dark h5 me-2"><AiOutlineCheckCircle className="align-middle"/></span>Beautiful and easy to understand animations</li>
                                <li className="mb-0"><span className="text-dark h5 me-2"><AiOutlineCheckCircle className="align-middle"/></span>Our Talented & Experienced Marketing Agency</li>
                                <li className="mb-0"><span className="text-dark h5 me-2"><AiOutlineCheckCircle className="align-middle"/></span>Theme advantages are pixel perfect design</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    {aboutData.map((item, index) =>{
                        const Icon = item.icon 
                        return(
                        <div className="col-lg-12 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0" key={index}>
                            <div className="card shadow p-4 rounded features features-classic feature-primary">
                                <Icon className="h1 mb-0 text-primary"/>

                                <div className="content my-3 border-bottom">
                                    <Link to="#" className="title h5 text-dark">{item.title}</Link>

                                    <p className="text-muted mt-3">{item.desc}</p>
                                </div>

                                <Link to="#" className="d-flex align-items-center justify-content-between">
                                    <span className="fw-medium text-dark">Apply Now</span>
                                   <FiArrowRight/>
                                </Link>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>

           
        </section>
        <FooterSeven/>
        <ScrollTop/>
        </>
    )
}