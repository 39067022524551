import React from "react";
import { Link } from "react-scroll";
import bg1 from "../assets/images/web/Contact-Us.jpg"
import NavbarTwo from "../components/navbar/navbarTwo";
import {FiPhone, FiMail,FiMapPin} from '../assets/icons/vander'
import FooterSeven from "../components/footer/footerSeven";
import ScrollTop from "../components/scrollTop";
import axios from "axios";

export default function Contact(){

    const handleSubmit = (event) => {
        event.preventDefault();
    
        const data = {
          name: event.target.elements.name.value,
          email: event.target.elements.email.value,
          subject: event.target.elements.subject.value,
          comments: event.target.elements.comments.value,
        };
    
        axios.post("/send_email.php", data)
          .then((response) => {
            if (response.data.success) {
              alert("Email sent successfully!");
            } else {
              alert(response.data.error);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };

    return(
        <>
        <NavbarTwo navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light"/>
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <small className="text-white-50 mb-1 fw-medium text-uppercase mx-auto">Get in touch</small>
                            <h5 className="heading fw-semibold mb-0 page-heading text-white title-dark">Contact us</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>

        <section className="section pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card border-0 text-center features feature-clean bg-transparent">
                            <div className="icons text-primary text-center mx-auto">
                                <FiPhone className="d-block rounded h3 mb-0"/>
                            </div>
                            <div className="content mt-3">
                                <h5 className="footer-head">Phone</h5>
                                {/* <p className="text-muted">Start working with integra that can provide everything</p> */}
                                <Link to="tel:+16618770709" className="text-foot">+1 6618770709</Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="card border-0 text-center features feature-clean bg-transparent">
                            <div className="icons text-primary text-center mx-auto">
                               <FiMail className="d-block rounded h3 mb-0"/>
                            </div>
                            <div className="content mt-3">
                                <h5 className="footer-head">Email</h5>
                                {/* <p className="text-muted">Start working with integra that can provide everything</p> */}
                                <Link to="mailto:contact@integraassist.com" className="text-foot">contact@integraassist.com</Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="card border-0 text-center features feature-clean bg-transparent">
                            <div className="icons text-primary text-center mx-auto">
                                <FiMapPin className="d-block rounded h3 mb-0"/>
                            </div>
                            <div className="content mt-3">
                                <h5 className="footer-head">Location</h5>
                                <p className="text-muted">440 East Huntington Dr,<br/> PMB # 1061 Arcadia, CA 91006</p>
                                <Link to="https://maps.app.goo.gl/gEKakosrUcMhUcSd6"
                                    data-type="iframe" className="video-play-icon text-foot lightbox">View on Google map</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-title mb-5 pb-2 text-center">
                            <h4 className="title mb-3">Get In Touch !</h4>
                            <p className="text-muted para-desc mx-auto mb-0">Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!</p>
                        </div>
                        <div className="custom-form">
                            <form  onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                                <input name="name" id="name" type="text" className="form-control" placeholder="Name :"/>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                <input name="email" id="email" type="email" className="form-control" placeholder="Email :"/>
                                        </div> 
                                    </div>

                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Subject</label>
                                                <input name="subject" id="subject" className="form-control" placeholder="Subject :"/>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Comments <span className="text-danger">*</span></label>
                                                <textarea name="comments" id="comments" rows="4" className="form-control" placeholder="Message :"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-grid">
                                            <button type="submit"  name="send" className="btn btn-primary">Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-100 mt-60">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="card map border-0">
                            <div className="card-body p-0">
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{border:'0'}}  title="integra" allowFullScreen></iframe> */}

                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d65016061.30381051!2d-151.96136927029193!3d-5.958119654096772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2dbdedbbb4125%3A0xda35ce27b9bca037!2sDental%20Group%20of%20Arcadia!5e0!3m2!1sen!2sin!4v1701875882627!5m2!1sen!2sin" style={{border:'0'}}  title="integra" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterSeven/>
        <ScrollTop/>
        </>
    )
}