import React from "react";
import { Link } from "react-router-dom";
import bg1 from '../assets/images/bg/services.jpg'
import blog1 from "../assets/images/web/services/it_consulting_main.jpg"
import blog2 from "../assets/images/web/services/it_staffing_main.jpg"
import blog3 from "../assets/images/web/services/Technology_main.jpg"
import NavbarTwo from "../components/navbar/navbarTwo";
import FooterSeven from "../components/footer/footerSeven";
import ScrollTop from "../components/scrollTop";
import 'tiny-slider/dist/tiny-slider.css';

export default function ServiceTwo(){
    
    return(
        <>
        <NavbarTwo navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light"/>

        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row g-0 align-items-center mt-5">
                    <div className="col-lg-8 col-md-6">
                        <div className="title-heading text-md-start text-center">
                            <h5 className="heading fw-semibold page-heading text-white title-dark">Our Services</h5>
                            <p className="text-white-50 mb-0">We Are Here To Help You</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 mt-sm-0">
                        <div className="text-md-end text-center">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    {/* <li className="breadcrumb-item"><Link to="#">Page</Link></li> */}
                                    <li className="breadcrumb-item active" aria-current="page">Services</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
        

             {/* Services display below code                    */}
            <div className="container mt-10 mt-6">
                <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                            <div className="card blog blog-primary shadow rounded overflow-hidden">
                                <div className="image position-relative overflow-hidden">
                                    <img src={blog1} className="img-fluid" style={{height:"17vw"}} alt=""/>
                                </div>

                                <div className="card-body content">
                                    <Link to="/it-consulting" className="h5 title text-dark d-block mb-0">IT Consulting</Link>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                            <div className="card blog blog-primary shadow rounded overflow-hidden">
                                <div className="image position-relative overflow-hidden">
                                    <img src={blog2} className="img-fluid" style={{height:"17vw"}} alt=""/>                                   
                                </div>

                                <div className="card-body content">
                                    <Link to="/it-staffing" className="h5 title text-dark d-block mb-0">IT Staffing</Link>
                                
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                            <div className="card blog blog-primary shadow rounded overflow-hidden">
                                <div className="image position-relative overflow-hidden">
                                    <img src={blog3} className="img-fluid" style={{height:"17vw"}} alt=""/>

                                    {/* <div className="blog-tag">
                                        <Link to="#" className="badge text-bg-light">Business</Link>
                                    </div> */}
                                </div>

                                <div className="card-body content">
                                    <Link to="/it-solutions" className="h5 title text-dark d-block mb-0">IT Solutions</Link>
                                    {/* <p className="text-muted mt-2 mb-2">The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.</p>
                                    <Link to="/blog-detail-three" className="link text-dark">Read More <FiArrowRight className="ms-1 align-middle"/></Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    
             
            </div>
            
         
        </section>
        <FooterSeven/>
        <ScrollTop/>
        </>
    )
}