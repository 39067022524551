import React,{useState} from "react";
import { Link } from "react-router-dom";
import bg1 from "../assets/images/web/client/Clients-Slider.jpg"
import NavbarTwo from "../components/navbar/navbarTwo"
import Partners from "../components/partners";
import FooterSeven from "../components/footer/footerSeven";
import ScrollTop from "../components/scrollTop";
import '../../node_modules/react-modal-video/css/modal-video.css';
import {FiAirplay, LiaReact, FiUsers} from '../assets/icons/vander'

export default function AboutUs(){
    const [isOpen, setOpen] = useState(false);
    const aboutData = [
        {
            icon:FiAirplay,
            title:'Developing strategy',
            desc:'It is said that song composers of the past used dummy texts as lyrics when writing to sing with the melody.'
        },
        {
            icon:LiaReact,
            title:'Blazing performance',
            desc:'It is said that song composers of the past used dummy texts as lyrics when writing to sing with the melody.'
        },
        {
            icon:FiUsers,
            title:'Customer satisfaction',
            desc:'It is said that song composers of the past used dummy texts as lyrics when writing to sing with the melody.'
        },
    ]
   
    return(
        <>
        <NavbarTwo navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light"/>

        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <small className="text-white-50 mb-1 fw-medium text-uppercase mx-auto">Who is our Clients</small>
                            <h5 className="heading fw-semibold mb-0 page-heading text-white title-dark">Clients</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Clients</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        
        <section className="section">
            <div className="container mt-0">
                <Partners/>
            </div>
        </section>
        <FooterSeven/>
        <ScrollTop/>
        </>
    )
}