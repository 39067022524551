import React from "react";
import { Link } from "react-router-dom";
import bg1 from "../assets/images/web/about/About-Us-Slider01.jpg"
import about from "../assets/images/web/about/About-US-003.jpg"
import about1 from "../assets/images/web/about/About-Us-02.jpg"
import cta from "../assets/images/web/about/About-US-004.jpg"
import NavbarTwo from "../components/navbar/navbarTwo"
import FooterSeven from "../components/footer/footerSeven";
import ScrollTop from "../components/scrollTop";
import '../../node_modules/react-modal-video/css/modal-video.css';
import TeamTwo from "../components/teamTwo";
import lapy from "../assets/images/web/work-process.png"


export default function AboutUs(){
    const processData = [
        {
            title:'Versatile Expertise',
            desc:'Harness the power of our diverse experience spanning various domains. Our consultants excel in addressing the distinctive demands of CRM, Mainframes, SAP EWM, Software Testing, and  Generative AI.'
        },
        {
            title:'Customized Solutions',
            desc:'We understand that every client is unique. Our consultants collaborate closely with you to comprehend your specific challenges, ensuring the delivery of tailor-made strategies aligned with your business objectives.'
        },
        {
            title:'Pioneering Innovation',
            desc:'Embrace the future of technology with our unwavering commitment to innovation. We continually explore emerging trends and technologies, empowering our clients to adapt to the ever-evolving IT landscape.'
        },
        {
            title:'Client-Centric Dedication',
            desc:'At Integra Assist, client satisfaction is paramount. We prioritize transparent communication and collaboration, fostering enduring partnerships. Our solutions dont just meet your expectations; they exceed them.'
        },
    ]
    return(
        <>
        <NavbarTwo navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light"/>

        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <small className="text-white-50 mb-1 fw-medium text-uppercase mx-auto">Who we are?</small>
                            <h5 className="heading fw-semibold mb-0 page-heading text-white title-dark">About Our Company</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6">
                        <img src={about1} className="img-fluid rounded shadow" alt=""/>
                    </div>

                    <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="section-title ms-lg-5">
                            <h4 className="title mb-3">COMPANY OVERVIEW OF INTEGRA ASSIST</h4>
                            <p className="text-muted">Integra Assist is a premier IT consultancy renowned for delivering customized solutions and expert guidance. Our seasoned professionals specialize in CRM, Mainframes, SAP Extended Warehouse Management, Software Testing, Generative AI, and beyond. We are committed to crafting tailored strategies that drive digital success, ensuring our clients thrive inthe ever-evolving technology landscape. With a proven reputation for reliability and innovation, we offer comprehensive consulting, staffing, and training solutions. At Integra Assist, we&#39;re not just a consultancy; we&#39;re a trusted partner on your journey to achieving IT excellence and business success in the digital age.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid px-0 mt-100 mt-60">
                <div className="bg-half-260" style={{backgroundImage:`url(${cta})`, backgroundPosition:'top'}}>
                </div>
            </div>

            <div className="container mt-100">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="section-title ms-lg-5">
                            <h4 className="title mb-3">Mission</h4>
                            <p className="text-muted">Empower businesses through data-driven decisions, innovative technology, and expert consulting to navigate complex challenges.</p>

                             <h4 className="title mb-3 mt-30">Vision</h4>
                            <p className="text-muted">Be a global leader in delivering transformative solutions, inspiring excellence, and fostering sustainable growth.</p>


                        </div>
                    </div>

                    <div className="col-lg-5 col-md-6">
                        <img src={about} className="img-fluid rounded shadow" alt=""/>
                    </div>
                </div>
            </div>

        </section>
        <section className="section bg-light overflow-hidden">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="me-lg-5">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="section-title text-center text-md-start mb-0 pb-2">
                                        <h4 className="title fw-semibold mb-0">Why Choose Integra Assist?</h4>
                                        {/* <p className="para-desc text-muted mx-auto mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {processData.map((item,index)=>{
                                    return(
                                    <div className="col-md-12 mt-4 pt-2" key={index}>
                                        <div className="features feature-primary rounded border-0 d-flex">
                                            <div className="fea-icon rounded-circle text-white title-dark shadow avatar avatar-md-sm" style={{width:'40px', height:'40px'}}>
                                                {index+1}
                                            </div>
                
                                            <div className="content flex-1 ms-3">
                                                <Link to="/page-single-service" className="title h5 text-dark">{item.title}</Link>
                                                <p className="text-muted mt-2 mb-0">{item.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}   
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="img-fluid-responsive position-relative">
                            <img src={lapy} className="mx-auto d-block" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section" style={{background:"#ffffff"}}>
            <div className="container mt-50 mt-60">
                <div className="row justify-content-center">
                    <div className="col-12 mb-4 pb-2">
                        <div className="section-title text-center">
                            <h6 className="text-primary">Our Team</h6>
                            <h4 className="title fw-semibold mt-2 mb-3">TEAM INTEGRA</h4>
                            <p className="text-muted mx-auto mb-0"> Comprising seasoned professionals with extensive expertise in CRM, Mainframes, SAP Extended Warehouse Management, Software Testing, Generative AI, and related fields, our team is well-versed in addressing a wide spectrum of IT challenges. We deliver customized solutions to drive your business's technological progress and excellence.</p>
                        </div>
                    </div>
                </div>
                <TeamTwo/>
            </div>                  
        </section>   
        <FooterSeven/>
        <ScrollTop/>
        </>
    )
}