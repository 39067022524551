import React from "react";
import { Link } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png"
import {AiOutlineShoppingCart, AiOutlineDribbble, AiOutlineBehance,AiFillLinkedin,BiLogoFacebook,AiOutlineInstagram,AiOutlineTwitter,FiMail,AiOutlineFile,IoIosArrowForward, FiSmartphone, FiGlobe} from "../../assets/icons/vander"

export default function FooterSeven(){
    return(
        <footer className="footer bg-footer"> 
          <div className="py-5 footer-border-bottom">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="row">
                                <div className="col-lg-9">
                                    <p className="foot-text mb-0 text-md-start text-light"><b>Connect With Us: </b> <br/> Seize the opportunity to transform your IT landscape with integra Assist. Contact us today for a consultation and unlock the potential of CRM, Mainframes, SAP EWM, Software Testing, and Generative At to propel your business toward unprecedented success. </p>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-3 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div className="text-md-end">
                                        <Link to="../contact" className="btn btn-primary mt-4">Contact Us</Link>
                                    </div>
                                </div>
                            </div>

                       
                    </div>
                </div>
            </div>           
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="footer-py-60">
                        <div className="row">
                            <div className="col-lg-4 col-12">
                                <Link to="#" className="logo-footer">
                                    <img src={logoLight} height="" style={{ marginLeft: "-10%"}} alt=""/>
                                </Link>
                                {/* <p className="mt-4 text-light">Integra Assist is a distinguished IT consultancy, dedicated to delivering tailored solutions and expert guidance for your digital success.</p> */}
                                <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                    <li className="list-inline-item"><Link to="https://1.envato.market/starty-react" target="_blank" className="rounded"><AiOutlineShoppingCart className="align-middle"/></Link></li>
                                    <li className="list-inline-item"><Link to="https://dribbble.com/shreethemes" target="_blank" className="rounded"><AiOutlineDribbble/></Link></li>
                                    <li className="list-inline-item"><Link to="https://www.behance.net/shreethemes" target="_blank" className="rounded"><AiOutlineBehance/></Link></li>
                                    <li className="list-inline-item"><Link to="http://linkedin.com/company/shreethemes" target="_blank" className="rounded"><AiFillLinkedin/></Link></li>
                                    <li className="list-inline-item"><Link to="https://www.facebook.com/shreethemes" target="_blank" className="rounded"><BiLogoFacebook/></Link></li>
                                    <li className="list-inline-item"><Link to="https://www.instagram.com/shreethemes/" target="_blank" className="rounded"><AiOutlineInstagram/></Link></li>
                                    <li className="list-inline-item"><Link to="https://twitter.com/shreethemes" target="_blank" className="rounded"><AiOutlineTwitter/></Link></li>
                                    <li className="list-inline-item"><Link to="mailto:support@shreethemes.in" className="rounded"><FiMail/></Link></li>
                                    <li className="list-inline-item"><Link to="https://forms.gle/QkTueCikDGqJnbky9" target="_blank" className="rounded"><AiOutlineFile/></Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-8 col-12">
                                <div className="row">
                                    <div className="col-md-3 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                        <h6 className="footer-head">Usefull links</h6>
                                        <ul className="list-unstyled footer-list mt-4">
                                            <li><Link to="/about-us" className="text-foot d-flex align-items-center text-light"><IoIosArrowForward className="me-1"/> About us</Link></li>
                                            <li><Link to="/services" className="text-foot d-flex align-items-center text-light"><IoIosArrowForward className="me-1"/> Services</Link></li>
                                            <li><Link to="/clients" className="text-foot d-flex align-items-center text-light"><IoIosArrowForward className="me-1"/> Client</Link></li>
                                            <li><Link to="/career" className="text-foot d-flex align-items-center text-light"><IoIosArrowForward className="me-1"/> Career</Link></li>
                                        </ul>
                                    </div>
                                    
                                    <div className="col-md-4 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                        <h6 className="footer-head">Services</h6>
                                        <ul className="list-unstyled footer-list mt-4">
                                            <li><Link to="/it-consulting" className="text-foot d-flex align-items-center text-light"><IoIosArrowForward className="me-1"/> IT Consulting</Link></li>
                                            <li><Link to="/it-staffing" className="text-foot d-flex align-items-center text-light"><IoIosArrowForward className="me-1"/> IT Staffing</Link></li>
                                            <li><Link to="/it-solutions" className="text-foot d-flex align-items-center text-light"><IoIosArrowForward className="me-1"/>IT Solutions</Link></li>
                                           
                                            
                                        </ul>
                                    </div>
    
                                    <div className="col-md-5 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                        <h6 className="footer-head">Contact Us</h6>
                                        <ul className="list-unstyled footer-list mt-4">
                                            <li><FiGlobe className="display-8 text-white title-dark"/> : <Link to="#" className="text-light title-dark mb-0">440 East Huntington Dr,PMB # 1061 Arcadia, CA 91006</Link></li>
                                            <li><FiSmartphone className="display-8 text-white title-dark"/> : <Link to="tel:+1 6618770709" className="text-light title-dark mb-0">+1 6618770709</Link></li>
                                            <li> <FiMail className="display-8 text-white title-dark"/> : <Link to="mailto:contact@integraassist.com" className="text-light title-dark mb-0">contact@integraassist.com</Link></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-py-30 footer-bar bg-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="text-center">
                            <p className="mb-0 text-light">{new Date().getFullYear()} © Inegra Assist.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}