import React from "react";
import { Link } from "react-router-dom";
import cta from "../../assets/images/cta02.jpg"
import bg1 from '../../assets/images/web/slider/Slider011.jpg'
import bg2 from '../../assets/images/web/slider/Slider002.jpg'
import image1 from "../../assets/images/web/meetings.jpg"
import NavbarTwo from "../../components/navbar/navbarTwo";
import Partners from "../../components/partners";
import Team from "../../components/teamTwo";
import ScrollTop from "../../components/scrollTop";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../../node_modules/react-modal-video/css/modal-video.css';
import {RiBarChartLine,BiReceipt,TbCurrencyBitcoin, } from '../../assets/icons/vander' 
import FooterSeven from "../../components/footer/footerSeven";
import lapy from "../../assets/images/web/work-process.png"

export default function IndexBusiness(){
    const aboutData = [
        {
            icon:RiBarChartLine,
            title:'IT Consulting',
            desc:'At Integra Assist, we offer adept IT consulting services fortified by robust IT Support & Infrastructure'
        },
        {
            icon:BiReceipt,
            title:'IT Staffing',
            desc:'At Integra Assist, we understand the critical role adept IT staffing plays in advancing your business towards its strategic goals.'
        },
        {
            icon:TbCurrencyBitcoin,
            title:'IT Solutions ',
            desc:'Due to its widespread use as filler text for layouts, non-readability is of great importance'
        },
    ]

    const processData = [
        {
            title:'Versatile Expertise',
            desc:'Harness the power of our diverse experience spanning various domains. Our consultants excel in addressing the distinctive demands of CRM, Mainframes, SAP EWM, Software Testing, and  Generative AI.'
        },
        {
            title:'Customized Solutions',
            desc:'We understand that every client is unique. Our consultants collaborate closely with you to comprehend your specific challenges, ensuring the delivery of tailor-made strategies aligned with your business objectives.'
        },
        {
            title:'Pioneering Innovation',
            desc:'Embrace the future of technology with our unwavering commitment to innovation. We continually explore emerging trends and technologies, empowering our clients to adapt to the ever-evolving IT landscape.'
        },
        {
            title:'Client-Centric Dedication',
            desc:'At Integra Assist, client satisfaction is paramount. We prioritize transparent communication and collaboration, fostering enduring partnerships. Our solutions dont just meet your expectations; they exceed them.'
        },
    ]
    return(
        <>
        <NavbarTwo navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light"/>
        <section className="home-slider position-relative">
            <Carousel showArrows={false} selectedItem={false} infiniteLoop={true} autoPlay={true} interval={3000} showStatus={false}>
                    <div className="carousel-item active" data-bs-interval="3000">
                        <div className="bg-home d-flex align-items-center" style={{backgroundImage:`url(${bg1})`}}>
                            <div className="bg-overlay bg-linear-gradient"></div>
                            <div className="container">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <div className="title-heading text-center">
                                                <h1 className="fw-semibold display-3 text-white title-dark mb-4">Driving Digital Transformation with Proven IT Consulting</h1>
                                                <p className="para-desc mx-auto text-white">Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!</p>
                                                
                                                <div className="mt-4 pt-2">
                                                    <Link to="/services" className="btn btn-primary">See Services</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item active" data-bs-interval="3000">
                        <div className="bg-home d-flex align-items-center" style={{backgroundImage:`url(${bg2})`}}>
                            <div className="bg-overlay bg-linear-gradient"></div>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="title-heading text-center">
                                            <h1 className="fw-semibold display-3 text-white title-dark mb-4">Protecting Your Digital World: Our Mission, Your Security</h1>
                                            <p className="para-desc mx-auto text-white">Empowering Success: Integra Assist's Customized IT Solutions and Expert Guidance</p>
                                            
                                            <div className="mt-4 pt-2">
                                                <Link to="/services" className="btn btn-primary">See Services</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
            </Carousel>
        </section>
        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-6 order-1 order-md-2">
                        <div className="features-absoluteTwo">
                            <div className="position-relative">
                                <img src={image1} className="img-fluid rounded shadow" alt=""/>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-6 order-2 order-md-1">
                        <div className="row">
                            {aboutData.map((item,index)=>{
                                const Icon = item.icon
                                return(
                                <div className="col-lg-6 col-12 mb-4 pt-2" key={index}>
                                    <div className="card features feature-primary">
                                        <Icon className="icon h1 d-block mb-3"/>
                                        <Link to="/services" className="h5 title text-dark">{item.title}</Link>
                                        <p className="text-muted mt-2 mb-0">{item.desc}</p>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
            {/* <img src={clientLogo} className="img-fluid" alt=""/> */}
            <Partners/>
            </div>
        </section>
        <section className="section bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 mb-4 pb-2">
                        <div className="section-title text-center">
                            <h6 className="text-primary">About Us</h6>
                            <h4 className="title fw-semibold mt-2 mb-3">COMPANY OVERVIEW OF INTEGRA ASSIST</h4>
                            <p className="text-muted mx-auto mb-0">At Integra Assist, we are your one-stop destination for IT consulting, staffing, and training solutions. Our seasoned experts cover a wide spectrum of IT domains, including CRM, Mainframes, SAP Extended Warehouse Management (EWM), Software Testing, and Generative AI. Whether you need strategic guidance, skilled professionals, or comprehensive training, we have you covered, ensuring your success in the ever-evolving world of technology.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className="section" style={{background:"#ffffff"}}>
            <div className="container mt-50 mt-60">
                <div className="row justify-content-center">
                    <div className="col-12 mb-4 pb-2">
                        <div className="section-title text-center">
                            <h6 className="text-primary">Our Team</h6>
                            <h4 className="title fw-semibold mt-2 mb-3">TEAM INTEGRA</h4>
                            <p className="text-muted mx-auto mb-0"> Comprising seasoned professionals with extensive expertise in CRM, Mainframes, SAP Extended Warehouse Management, Software Testing, Generative AI, and related fields, our team is well-versed in addressing a wide spectrum of IT challenges. We deliver customized solutions to drive your business's technological progress and excellence.</p>
                        </div>
                    </div>
                </div>
                <Team/>
            </div>                  
        </section>  

        <div className="container-fluid px-0">
            <div className="py-5 position-relative" style={{backgroundImage:`url(${cta})`, backgroundPosition:'center'}}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container my-5">
                    <div className="row align-items-center">
                        <div className="col-lg-9 col-md-8">
                            <h4 className="display-6 h4 mb-0 text-white title-dark fw-medium">Elevate Your IT Strategy Today. Get in Touch for Customized Solutions and Expert Consultation</h4>
                        </div>

                        <div className="col-lg-3 col-md-4 mt-4 mt-sm-0 text-md-center">
                            <Link to="/contact" className="btn btn-light">Contact our Expert</Link>
                            {/* <p className="text-white-50 mb-0 mt-2">or free trial</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <section className="section">
           <ClientTwo/>
            <div className="container mt-100 mt-60">
               <BlogTwo/>
            </div>
        </section> */}
       
        <section className="section bg-light overflow-hidden">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="me-lg-5">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="section-title text-center text-md-start mb-0 pb-2">
                                        <h4 className="title fw-semibold mb-0">Why Choose Integra Assist?</h4>
                                        {/* <p className="para-desc text-muted mx-auto mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {processData.map((item,index)=>{
                                    return(
                                    <div className="col-md-12 mt-4 pt-2" key={index}>
                                        <div className="features feature-primary rounded border-0 d-flex">
                                            <div className="fea-icon rounded-circle text-white title-dark shadow avatar avatar-md-sm" style={{width:'40px', height:'40px'}}>
                                                {index+1}
                                            </div>
                
                                            <div className="content flex-1 ms-3">
                                                <Link to="/page-single-service" className="title h5 text-dark">{item.title}</Link>
                                                <p className="text-muted mt-2 mb-0">{item.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}   
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="img-fluid-responsive position-relative">
                            <img src={lapy} className="mx-auto d-block" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterSeven/>
        <ScrollTop/>
        </>
    )
}