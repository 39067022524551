import React from "react";
import client1 from "../../assets/images/client/01.jpg"
import client2 from "../../assets/images/client/02.jpg"
import client3 from "../../assets/images/client/03.jpg"

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

export default function ClientTwo(){
    const settings = {
        container: '.tiny-single-item',
        items: 1,
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
      };
    const clientData = [
        {
            image:client1,
            name:'Teja',
            title:' Cybersecurity Expert ',
            desc:'" Working at Integra has provided me with invaluable experience in cybersecurity. The team is knowledgeable and always willing to collaborate. I feel valued and part of a team thats making a real difference in technology security. "'
        },
        {
            image:client2,
            name:'Elvis',
            title:'Senior IT Consultant',
            desc:'" As a Senior IT Consultant, I have been able to work on diverse projects that challenge me every day. The company culture promotes innovation and learning, allowing me to stay at the forefront of technology trends. "'
        },
        {
            image:client3,
            name:'Lalasa',
            title:' Staffing Manager',
            desc:'"  Integra’s commitment to integrity and quality is evident in every placement we make. We are not just filling positions; we are building teams that drive success. I am proud to contribute to such a purposeful mission. "'
        },
        {
            image:client3,
            name:'Vasudeva P.',
            title:'IT Project Manager ',
            desc:'" Leading projects in such a dynamic and supportive environment has been incredibly fulfilling. The leadership team values our input, driving a culture of continuous improvement. "'
        },
        {
            image:client3,
            name:'Lalasa',
            title:'Mainframe',
            desc:'" At Integra Assist, as a Mainframes Specialist, I appreciate the dynamic environment, challenging projects, and opportunities for growth, contributing to our clients success. "'
        },
    ]
    return(
        <>
        <div className="conatainer">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="tiny-single-item">
                        <TinySlider settings={settings}>
                            {clientData.map((item,index)=>{
                                return(
                                    <div className="tiny-slide px-md-5" key={index}>
                                        <div className="card client-testi text-center">
                                            {/* <img src={item.image} className="avatar avatar-small rounded-pill mx-auto" alt=""/> */}

                                            <div className="card-body pb-0 content">
                                                <p className="h5 fw-normal text-muted fst-italic">{item.desc}</p>

                                                <div className="name mt-4">
                                                    <small className="text-uppercase fw-semibold d-block">{item.name}</small>
                                                    <small className="text-muted">{item.title}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </TinySlider>    
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}