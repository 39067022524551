import React from "react";
import { Link } from "react-router-dom";
import bg1 from "../assets/images/web/services/technology_header.jpg"
import about from "../assets/images/web/services/Technology_main.jpg"
import image1 from "../assets/images/web/services/service_sidebar.jpg"
import NavbarTwo from "../components/navbar/navbarTwo";
import FooterSeven from "../components/footer/footerSeven";
import ScrollTop from "../components/scrollTop";

export default function SingleService(){
    return(
        <>
        <NavbarTwo navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light"/>

        {/* <section className="bg-half-80 d-table bg-primary bg-gradient w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}> */}
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row g-0 align-items-center mt-5">
                    <div className="col-lg-8 col-md-6">
                        <div className="title-heading text-md-start text-center">
                            <h5 className="heading fw-semibold page-heading text-white title-dark">IT Solutions </h5>
                            <p className="text-white-50 mb-0">Walking is the best possible exercise</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 mt-sm-0">
                        <div className="text-md-end text-center">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/services">Services</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">IT Solutions</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-6">
                        <div className="section-title">
                            <img src={about} className="img-fluid rounded" alt=""/>

                            {/* <h4 className="mt-4">Preparing for your success with web development</h4> */}

                            <p className="text-muted mt-4"> At Integra Assist, we understand that the ever-evolving IT landscape requires holistic solutions. Our expertise spans CRM, Mainframes, SAP Extended Warehouse Management (EWM), Software Testing, and cutting-edge Generative AI. We offer a comprehensive approach to tackle modern business challenges, providing strategic guidance, flawless implementation, and continuous innovation across diverse IT domains.</p>

                            <h4 className="mt-4">Our Specializations </h4>
                            <h5 className="mt-4">Cyber Security : </h5>
                            <p className="text-muted mt-4">Round-the-clock monitoring, intelligence-driven threat assessment, rapid incident response, and tailored security training, ensuring data protection and compliance in the face of evolving digital threats.</p>

                            <h5 className="mt-4">CRM Excellence : </h5>
                            <p className="text-muted mt-4">Enhance customer relationships with our CRM consulting. We assist in CRM selection, customization, and integration, focusing on strategic CRM utilization for improved engagement and growth.</p>

                            <h5 className="mt-4">Mainframe Mastery : </h5>
                            <p className="text-muted mt-4">In the modern IT era, mainframes remain crucial. Our Mainframe Mastery services cover analysis, design, optimization, migration, and security compliance, ensuring seamless alignment with your goals. </p>

                            <h5 className="mt-4">SAP EWM Solutions : </h5>
                            <p className="text-muted mt-4">Elevate supply chain and warehouse operations with SAP EWM. We provide end-to-end solutions, including implementation, customization, process enhancement, and ongoing support. </p>

                            <h5 className="mt-4">Software Testing Excellence : </h5>
                            <p className="text-muted mt-4">Quality assurance is paramount. Our Software Testing Excellence services encompass comprehensive test planning, execution, and automation to ensure software reliability, security, and performance. </p>

                            <h5 className="mt-4">Generative AI Innovation : </h5>
                            <p className="text-muted mt-4">Explore Generative AI's potential with us. Our services leverage advanced algorithms for content generation, image synthesis, and more, driving innovation in your processes. </p>

                            <h5 className="mt-4">Oracle Expertise Unleashed : </h5>
                            <p className="text-muted mt-4">Elevate your enterprise with our Oracle specialists in Financials, SCM, CRM, Technical, and Cloud Integrations. Experience unparalleled service and innovative solutions tailored for success. </p>

                            <h5 className="mt-4">Comprehensive Application Support : </h5>
                            <p className="text-muted mt-4">Expert support for all applications across industries. Reliable, efficient solutions ensuring seamless operation and growth.  </p>

                           
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="sidebar sticky-bar">
                            <div className="widget text-center position-relative">
                                <div className="rounded shadow bg-white">
                                    <div className="position-relative">
                                        <img src={image1} className="img-fluid rounded shadow" alt=""/>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="widget mt-4 pt-2">
                            <div className="card bg-white shadow rounded border-0">
                                    <div className="card-body">
                                        <h4 className="card-title">Get In Touch !</h4>
                                        <div className="custom-form mt-4">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                                            <input name="name" id="name" type="text" className="form-control" placeholder="Name :"/>
                                                        </div>
                                                    </div>
                
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                            <input name="email" id="email" type="email" className="form-control" placeholder="Email :"/>
                                                        </div> 
                                                    </div>
                
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Subject</label>
                                                            <input name="subject" id="subject" className="form-control" placeholder="Subject :"/>
                                                        </div>
                                                    </div>
                
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Comments <span className="text-danger">*</span></label>
                                                            <textarea name="comments" id="comments" rows="3" className="form-control" placeholder="Message :"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button className="btn btn-primary">Send Message</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterSeven/>
        <ScrollTop/>
        </>
    )
}