import React from "react";
import { Route, Routes} from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/style.scss'
import './assets/css/materialdesignicons.min.css'
import IndexBusiness from "./pages/index/index-business";
import AboutUs from "./pages/about-us";
import Service from "./pages/page-services";
import ServiceTwo from "./pages/services";
import Consulting from "./pages/it-consulting";
import Staffing from "./pages/it-staffing";
import Technology from "./pages/it-solutions";
import SingleService from "./pages/page-single-service";
import Faqs from "./pages/page-faqs";
import PageTeam from "./pages/page-team";

import Contact from "./pages/contact";
import Careers from "./pages/career";
import Employee from "./pages/employee";
import Client from "./pages/clients";
import Emptestimonial from "./pages/employee-testimonial";

function App() {
  return (
   <>
   <Routes>
     <Route path="/" element={<IndexBusiness/>}/>
     <Route path="/about-us" element={<AboutUs/>}/>
     <Route path="/clients" element={<Client/>}/>
     <Route path="/page-services" element={<Service/>}/>
     <Route path="/services" element={<ServiceTwo/>}/>
     <Route path="/it-consulting" element={<Consulting/>}/>
     <Route path="/it-staffing" element={<Staffing/>}/>
     <Route path="/it-solutions" element={<Technology/>}/>
     <Route path="/page-single-service" element={<SingleService/>}/>
     <Route path="/page-faqs" element={<Faqs/>}/>
  
     <Route path="/page-team" element={<PageTeam/>}/>
     <Route path="/contact" element={<Contact/>}/>
     <Route path="/career" element={<Careers/>}/>
     <Route path="/employee" element={<Employee/>}/>
     <Route path="/employee-testimonial" element={<Emptestimonial/>}/>
   </Routes>
   </>
  );
}

export default App;
