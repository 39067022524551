import blog1 from "../assets/images/blog/01.jpg"
import blog2 from "../assets/images/blog/02.jpg"
import blog3 from "../assets/images/blog/03.jpg"
import blog4 from "../assets/images/blog/04.jpg"
import blog5 from "../assets/images/blog/05.jpg"
import blog6 from "../assets/images/blog/06.jpg"
import blog7 from "../assets/images/blog/07.jpg"
import blog8 from "../assets/images/blog/08.jpg"
import blog9 from "../assets/images/blog/09.jpg"
import blog10 from "../assets/images/blog/img01.jpg"
import blog11 from "../assets/images/blog/img02.jpg"
import blog12 from "../assets/images/blog/img03.jpg"
import blog13 from "../assets/images/blog/img04.jpg"
import blog14 from "../assets/images/blog/img05.jpg"
import blog15 from "../assets/images/blog/img06.jpg"
import blog16 from "../assets/images/blog/img07.jpg"
import blog17 from "../assets/images/blog/img08.jpg"
import blog18 from "../assets/images/blog/img09.jpg"

import portfolio1 from "../assets/images/portfolio/01.jpg"
import portfolio2 from "../assets/images/portfolio/02.jpg"
import portfolio3 from "../assets/images/portfolio/03.jpg"
import portfolio4 from "../assets/images/portfolio/04.jpg"
import portfolio5 from "../assets/images/portfolio/05.jpg"
import portfolio6 from "../assets/images/portfolio/06.jpg"
import portfolio7 from "../assets/images/portfolio/07.jpg"
import portfolio8 from "../assets/images/portfolio/08.jpg"
import portfolio9 from "../assets/images/portfolio/09.jpg"
import portfolio10 from "../assets/images/portfolio/10.jpg"
import portfolio11 from "../assets/images/portfolio/11.jpg"
import portfolio12 from "../assets/images/portfolio/12.jpg"
import portfolio13 from "../assets/images/portfolio/13.jpg"
import portfolio14 from "../assets/images/portfolio/14.jpg"
import portfolio15 from "../assets/images/portfolio/15.jpg"
import portfolio16 from "../assets/images/portfolio/16.jpg"
import portfolio17 from "../assets/images/portfolio/17.jpg"
import portfolio18 from "../assets/images/portfolio/18.jpg"

import causes1 from "../assets/images/charity/cause01.jpg"
import causes2 from "../assets/images/charity/cause02.jpg"
import causes3 from "../assets/images/charity/cause03.jpg"
import causes4 from "../assets/images/charity/cause04.jpg"
import causes5 from "../assets/images/charity/cause05.jpg"
import causes6 from "../assets/images/charity/cause06.jpg"

import coin1 from "../assets/images/crypto/bitcoin.png"
import coin2 from "../assets/images/crypto/coinye.png"
import coin3 from "../assets/images/crypto/ethereum.png"
import coin4 from "../assets/images/crypto/blocknet.png"
import coin5 from "../assets/images/crypto/kucoin.png"
import coin6 from "../assets/images/crypto/bittorrent.png"
import coin7 from "../assets/images/crypto/avalanche.png"
import coin8 from "../assets/images/crypto/bitcoin-cash.png"
import coin9 from "../assets/images/crypto/binance.png"
import coin10 from "../assets/images/crypto/monero.png"

import client1 from "../assets/images/client/01.jpg"
import client2 from "../assets/images/client/02.jpg"
import client3 from "../assets/images/client/03.jpg"
import client4 from "../assets/images/client/04.jpg"
import client5 from "../assets/images/client/05.jpg"
import client6 from "../assets/images/client/06.jpg"
import client7 from "../assets/images/client/07.jpg"
import client8 from "../assets/images/client/08.jpg"
import client9 from "../assets/images/client/09.jpg"

import manu1 from "../assets/images/portfolio/rest/01.jpg"
import manu2 from "../assets/images/portfolio/rest/02.jpg"
import manu3 from "../assets/images/portfolio/rest/03.jpg"
import manu4 from "../assets/images/portfolio/rest/04.jpg"
import manu5 from "../assets/images/portfolio/rest/05.jpg"
import manu6 from "../assets/images/portfolio/rest/06.jpg"
import manu7 from "../assets/images/portfolio/rest/07.jpg"
import manu8 from "../assets/images/portfolio/rest/08.jpg"
import manu9 from "../assets/images/portfolio/rest/09.jpg"
import manu10 from "../assets/images/portfolio/rest/10.jpg"
import manu11 from "../assets/images/portfolio/rest/11.jpg"
import manu12 from "../assets/images/portfolio/rest/12.jpg"

import product1 from "../assets/images/shop/items/f1.png"
import product2 from "../assets/images/shop/items/f2.png"
import product3 from "../assets/images/shop/items/f3.png"
import product4 from "../assets/images/shop/items/f4.png"
import product5 from "../assets/images/shop/items/f5.png"
import product6 from "../assets/images/shop/items/f6.png"
import product7 from "../assets/images/shop/items/f7.png"
import product8 from "../assets/images/shop/items/f8.png"
import product9 from "../assets/images/shop/items/f9.png"
import product10 from "../assets/images/shop/items/f10.png"
import product11 from "../assets/images/shop/items/f11.png"
import product12 from "../assets/images/shop/items/f12.png"
import product13 from "../assets/images/shop/items/f13.png"
import product14 from "../assets/images/shop/items/f14.png"

import productBack1 from "../assets/images/shop/items/b1.png"
import productBack2 from "../assets/images/shop/items/b2.png"
import productBack3 from "../assets/images/shop/items/b3.png"
import productBack4 from "../assets/images/shop/items/b4.png"
import productBack5 from "../assets/images/shop/items/b5.png"
import productBack6 from "../assets/images/shop/items/b6.png"
import productBack7 from "../assets/images/shop/items/b7.png"
import productBack8 from "../assets/images/shop/items/b8.png"


export const blogData =[
    {
        id:1,
        image:blog1,
        tag:'Corporate',
        title:'Building Your Corporate Identity from integra',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'13th Sep 2023',
        client:client1,
        name:'Cristino Murphy',
    },
    {
        id:2,
        image:blog2,
        tag:'Branding',
        title:'The Dark Side of Overnight Success',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'29th Nov 2023',
        client:client2,
        name:'Florine Jackson',
    },
    {
        id:3,
        image:blog3,
        tag:'Technology',
        title:'The Right Hand of Business IT World',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'29th Dec 2023',
        client:client3,
        name:'Ida Wild',
    },
    {
        id:4,
        image:blog4,
        tag:'Personal',
        title:'How to Create Your Own Viral Moments',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'13th March 2023',
        client:client4,
        name:'Calvin Carlo',
    },
    {
        id:5,
        image:blog5,
        tag:'Business',
        title:'How to Write a Business Plan For Any Business',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'5th May 2023',
        client:client5,
        name:'Cristino Murphy',
    },
    {
        id:6,
        image:blog6,
        tag:'Marketing',
        title:'Seeing the Customer Journey More Clearly',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'19th June 2023',
        client:client6,
        name:'Florine Jackson',
    },
    {
        id:7,
        image:blog7,
        tag:'Production',
        title:'The Signs of a Highly Giftable Product',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'20th June 2023',
        client:client7,
        name:'Ida Wild',
    },
    {
        id:8,
        image:blog8,
        tag:'Business',
        title:'Defining Your Business Target Audience',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'31st Aug 2023',
        client:client8,
        name:'Calvin Carlo',
    },
    {
        id:9,
        image:blog9,
        tag:'Corporate',
        title:'Running Out of Time & Ideas? Visit Our Blog',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'1st Sep 2023',
        client:client9,
        name:'Cristino Murphy',
    },
    
]
export const blogMasonry = [
    {
        id:1,
        image:blog1,
        tag:'Corporate',
        title:'Building Your Corporate Identity from integra',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'13th Sep 2023',
        client:client1,
        name:'Cristino Murphy',
    },
    {
        id:2,
        image:blog10,
        tag:'Branding',
        title:'The Dark Side of Overnight Success',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'29th Nov 2023',
        client:client2,
        name:'Florine Jackson',
    },
    {
        id:3,
        image:blog3,
        tag:'Technology',
        title:'The Right Hand of Business IT World',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'29th Dec 2023',
        client:client3,
        name:'Ida Wild',
    },
    {
        id:4,
        image:blog11,
        tag:'Personal',
        title:'How to Create Your Own Viral Moments',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'13th March 2023',
        client:client4,
        name:'Calvin Carlo',
    },
    {
        id:5,
        image:blog12,
        tag:'Business',
        title:'How to Write a Business Plan For Any Business',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'5th May 2023',
        client:client5,
        name:'Cristino Murphy',
    },
    {
        id:6,
        image:blog6,
        tag:'Marketing',
        title:'Seeing the Customer Journey More Clearly',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'19th June 2023',
        client:client6,
        name:'Florine Jackson',
    },
    {
        id:7,
        image:blog7,
        tag:'Production',
        title:'The Signs of a Highly Giftable Product',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'20th June 2023',
        client:client7,
        name:'Ida Wild',
    },
    {
        id:8,
        image:blog8,
        tag:'Business',
        title:'Defining Your Business Target Audience',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'31st Aug 2023',
        client:client8,
        name:'Calvin Carlo',
    },
    {
        id:9,
        image:blog9,
        tag:'Corporate',
        title:'Running Out of Time & Ideas? Visit Our Blog',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        date:'1st Sep 2023',
        client:client9,
        name:'Cristino Murphy',
    },
    
]

export const BlogImageData = [
    {
        id:1,
        image:blog10,
        tag:'Finance',
        title:'How to Get a Successful Ad for Your Business',
        client:client1,
        name:'Cristino Murphy',
        date:'13th Sep 2023 '
    },
    {
        id:2,
        image:blog11,
        tag:'Branding',
        title:'The Dark Side of Overnight Success',
        client:client2,
        name:'Florine Jackson',
        date:'29th Nov 2023 '
    },
    {
        id:3,
        image:blog12,
        tag:'Technology',
        title:'The Right Hand of Business IT World',
        client:client3,
        name:'Ida Wild',
        date:'29th Dec 2023 '
    },
    {
        id:4,
        image:blog13,
        tag:'Personal',
        title:'How to Create Your Own Viral Moments',
        client:client4,
        name:'Calvin Carlo',
        date:'13th March 2023 '
    },
    {
        id:5,
        image:blog14,
        tag:'Business',
        title:'How to Write a Business Plan For Any Business',
        client:client5,
        name:'Cristino Murphy',
        date:'5th May 2023 '
    },
    {
        id:6,
        image:blog15,
        tag:'Marketing',
        title:'Seeing the Customer Journey More Clearly',
        client:client6,
        name:'Florine Jackson',
        date:'19th June 2023 '
    },
    {
        id:7,
        image:blog16,
        tag:'Production',
        title:'The Signs of a Highly Giftable Product',
        client:client7,
        name:'Ida Wild',
        date:'20th June 2023 '
    },
    {
        id:8,
        image:blog17,
        tag:'Business',
        title:'Defining Your Business Target Audience',
        client:client8,
        name:'Calvin Carlo',
        date:'31st Aug 2023 '
    },
    {
        id:9,
        image:blog18,
        tag:'Corporate',
        title:'Running Out of Time & Ideas? Visit Our Blog',
        client:client9,
        name:'Cristino Murphy',
        date:'1st Sep 2023 '
    },
]
export const BlogImageMasonryData = [
    {
        id:1,
        image:blog10,
        tag:'Finance',
        title:'How to Get a Successful Ad for Your Business',
        client:client1,
        name:'Cristino Murphy',
        date:'13th Sep 2023 '
    },
    {
        id:2,
        image:blog2,
        tag:'Branding',
        title:'The Dark Side of Overnight Success',
        client:client2,
        name:'Florine Jackson',
        date:'29th Nov 2023 '
    },
    {
        id:3,
        image:blog12,
        tag:'Technology',
        title:'The Right Hand of Business IT World',
        client:client3,
        name:'Ida Wild',
        date:'29th Dec 2023 '
    },
    {
        id:4,
        image:blog13,
        tag:'Personal',
        title:'How to Create Your Own Viral Moments',
        client:client4,
        name:'Calvin Carlo',
        date:'13th March 2023 '
    },
    {
        id:5,
        image:blog5,
        tag:'Business',
        title:'How to Write a Business Plan For Any Business',
        client:client5,
        name:'Cristino Murphy',
        date:'5th May 2023 '
    },
    {
        id:6,
        image:blog6,
        tag:'Marketing',
        title:'Seeing the Customer Journey More Clearly',
        client:client6,
        name:'Florine Jackson',
        date:'19th June 2023 '
    },
    {
        id:7,
        image:blog16,
        tag:'Production',
        title:'The Signs of a Highly Giftable Product',
        client:client7,
        name:'Ida Wild',
        date:'20th June 2023 '
    },
    {
        id:8,
        image:blog8,
        tag:'Business',
        title:'Defining Your Business Target Audience',
        client:client8,
        name:'Calvin Carlo',
        date:'31st Aug 2023 '
    },
    {
        id:9,
        image:blog18,
        tag:'Corporate',
        title:'Running Out of Time & Ideas? Visit Our Blog',
        client:client9,
        name:'Cristino Murphy',
        date:'1st Sep 2023 '
    },
]

export const projectsData = [
    {
        id:1,
        image:portfolio1,
        title:'The Leaf and Letter',
        name:'Branding',
        category:'branding'
    },
    {
        id:2,
        image:portfolio2,
        title:'The Papers',
        name:'Designing',
        category:'designing'
    },
    {
        id:3,
        image:portfolio3,
        title:'The Headphones',
        name:'Photography',
        category:'photography'
    },
    {
        id:4,
        image:portfolio4,
        title:'The Projects',
        name:'Development',
        category:'development'
    },
    {
        id:5,
        image:portfolio5,
        title:'The Cup of Coffee',
        name:'Branding',
        category:'branding'
    },
    {
        id:6,
        image:portfolio6,
        title:'The Pen and Books',
        name:'Designing',
        category:'designing'
    },
    {
        id:7,
        image:portfolio7,
        title:'The Strawberry',
        name:'Photography',
        category:'photography'
    },
    {
        id:8,
        image:portfolio8,
        title:'The Leaf and Letter',
        name:'Development',
        category:'development'
    },
    {
        id:9,
        image:portfolio9,
        title:'The Cactus Plant',
        name:'Branding',
        category:'branding'
    },
    {
        id:10,
        image:portfolio10,
        title:'The Cactus Plant',
        name:'Branding',
        category:'branding'
    },
]
export const projectImage = [portfolio1,portfolio2, portfolio3, portfolio4, portfolio5, portfolio6, portfolio7, portfolio8, portfolio9, portfolio9]

export const popularCauses = [
    {
        image:causes1,
        title:'Donate For Food',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        progress:'60%',
        rais:'$600000',
        start:'$100000',
        goal:'$1000000'
    },  {
        image:causes2,
        title:'Donate For Food',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        progress:'60%',
        rais:'$600000',
        start:'$100000',
        goal:'$1000000'
    },
    {
        image:causes3,
        title:'Donate For Food',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        progress:'60%',
        rais:'$600000',
        start:'$100000',
        goal:'$1000000'
    },
    {
        image:causes4,
        title:'Donate For Food',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        progress:'60%',
        rais:'$600000',
        start:'$100000',
        goal:'$1000000'
    },
    {
        image:causes5,
        title:'Donate For Food',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        progress:'60%',
        rais:'$600000',
        start:'$100000',
        goal:'$1000000'
    },
    {
        image:causes6,
        title:'Donate For Food',
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        progress:'60%',
        rais:'$600000',
        start:'$100000',
        goal:'$1000000'
    }
]

export const cryptoPricing = [
    {
        image:coin1,
        title1:'Bitcoin',
        title2:'BTC',
        price:'$34587',
        change1:'-2.5%',
        change2:'-$745',
        marketcap:'$725,354M',
        profit:false
    },
    {
        image:coin2,
        title1:'Coinye',
        title2:'CNY',
        price:'$154',
        change1:'+1.05%',
        change2:'+1.05',
        marketcap:'$85,478M',
        profit:true
    },
    {
        image:coin3,
        title1:'Ethereum Coin',
        title2:'ETH',
        price:'$854',
        change1:'+1.705%',
        change2:'+$1.705',
        marketcap:'$112,452M',
        profit:true
    },
    {
        image:coin4,
        title1:'Blocknet',
        title2:'BLOCK',
        price:'$478',
        change1:'+2.8%',
        change2:'+2.8',
        marketcap:'$66,552M',
        profit:true
    },
    {
        image:coin5,
        title1:'Kucoin',
        title2:'KCS',
        price:'$545',
        change1:'+1.5%',
        change2:'+$1.5',
        marketcap:'$98,562M',
        profit:true
    },
    {
        image:coin6,
        title1:'Bittorrent',
        title2:'BTT',
        price:'$4935',
        change1:'-3.2%',
        change2:'-$3.2',
        marketcap:'$663,214M',
        profit:false
    },
    {
        image:coin7,
        title1:'Avalanche',
        title2:'AVAX',
        price:'$1008',
        change1:'+1.4%',
        change2:'+$1.4',
        marketcap:'$88,512M',
        profit:true
    },
    {
        image:coin8,
        title1:'Bitcoin cash',
        title2:'BCS',
        price:'$816',
        change1:'+4.65%',
        change2:'+$4.65',
        marketcap:'$54,212M',
        profit:true
    },
    {
        image:coin9,
        title1:'Binance',
        title2:'BNB',
        price:'$965',
        change1:'-2.45%',
        change2:'-$2.45',
        marketcap:'$458,122M',
        profit:false
    },
    {
        image:coin10,
        title1:'Monero',
        title2:'XMR',
        price:'$4758',
        change1:'+3.5%',
        change2:'+$3.5',
        marketcap:'$52,142M',
        profit:true
    },
]

export const manuData = [
    {
        image:manu1,
        title:'Cupcake Recipes',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'break'
    },
    {
        image:manu2,
        title:'Onion Pizza',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'lunch'
    },
    {
        image:manu3,
        title:'Chicken Breast',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'dinner'
    },
    {
        image:manu4,
        title:'Veg Pizza',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'break'
    },
    {
        image:manu5,
        title:'Cordon Bleu',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'lunch'
    },
    {
        image:manu6,
        title:'Boerewors',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'lunch'
    },
    {
        image:manu7,
        title:'Tarte Tatin',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'dinner'
    },
    {
        image:manu8,
        title:'Green Tea',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'tea'
    },
    {
        image:manu9,
        title:'Special Coffee',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'break'
    },
    {
        image:manu10,
        title:'Lemon Tea',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'tea'
    },
    {
        image:manu11,
        title:'Pancakes',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'dinner'
    },
    {
        image:manu12,
        title:'American Item',
        desc:'A reader will be distracted by the readable',
        amount:'$13.79',
        category:'tea'
    },
]

export const productData =[
    {
        id:1,
        image1:product1,
        image2:productBack1,
        tag:'Featured',
        tagClass:'text-bg-success',
        product:'T-Shirt',
        amount:'$16.00',
    },
    {
        id:2,
        image1:product2,
        image2:productBack2,
        product:'Hoodie',
        amount:'$16.00',
    },
    {
        id:3,
        image1:product3,
        image2:productBack3,
        tag:'Sale',
        tagClass:'text-bg-dark',
        product:'Red Cap',
        amount:'$16.00',
    },
    {
        id:4,
        image1:product4,
        image2:productBack4,
        tag:'New',
        tagClass:'text-bg-primary',
        product:'White T-Shirt',
        amount:'$16.00',
    },
    {
        id:5,
        image1:product5,
        image2:productBack5,
        product:'Blue T-Shirt',
        amount:'$16.00',
    },
    {
        id:6,
        image1:product6,
        image2:productBack6,
        product:'Green T-Shirt',
        amount:'$16.00',
    },
    {
        id:7,
        image1:product7,
        image2:productBack7,
        product:'Full Sleeve T-Shirt',
        amount:'$16.00',
    },
    {
        id:8,
        image1:product8,
        image2:productBack8,
        product:'T-Shirt',
        amount:'$16.00',
    },
]
export const productData2 =[
    {
        id:1,
        image1:product9,
        image2:product9,
        product:'Women Block Heels',
        amount:'$16.00',
    },
    {
        id:2,
        image1:product10,
        image2:product10,
        tag:'Sale',
        tagClass:'text-bg-dark',
        product:'Hoodie',
        amount:'$16.00',
    },
    {
        id:3,
        image1:product11,
        image2:product11,
        tag:'New',
        tagClass:'text-bg-primary',
        product:'Red Cap',
        amount:'$16.00',
    },
    {
        id:4,
        image1:product12,
        image2:product12,
        product:'Branded T-Shirt',
        amount:'$16.00',
    },
    {
        id:5,
        image1:product13,
        image2:product13,
        product:'Blue T-Shirt',
        amount:'$16.00',
    },
    {
        id:6,
        image1:product14,
        image2:product14,
        product:'Green T-Shirt',
        amount:'$16.00',
    },
]

export const portfolioData = [
    {
        id:1,
        image:portfolio1,
        title:'The Leaf and Letter',
        name:'Branding',
        category:'branding'
    },
    {
        id:2,
        image:portfolio2,
        title:'The Papers',
        name:'Designing',
        category:'designing'
    },
    {
        id:3,
        image:portfolio3,
        title:'The Headphones',
        name:'Photography',
        category:'photography'
    },
    {
        id:4,
        image:portfolio4,
        title:'The Projects',
        name:'Development',
        category:'development'
    },
    {
        id:5,
        image:portfolio5,
        title:'The Cup of Coffee',
        name:'Branding',
        category:'branding'
    },
    {
        id:6,
        image:portfolio6,
        title:'The Pen and Books',
        name:'Designing',
        category:'designing'
    },
    {
        id:7,
        image:portfolio7,
        title:'The Strawberry',
        name:'Photography',
        category:'photography'
    },
    {
        id:8,
        image:portfolio8,
        title:'The Leaf and Books',
        name:'Development',
        category:'development'
    },
    {
        id:9,
        image:portfolio9,
        title:'The Cactus Plant',
        name:'Branding',
        category:'branding'
    },
    {
        id:10,
        image:portfolio10,
        title:'The Yellow Bag',
        name:'Photography',
        category:'photography'
    },
    {
        id:11,
        image:portfolio11,
        title:'The Teapot Table',
        name:'Designing',
        category:'designing'
    },
    {
        id:12,
        image:portfolio12,
        title:'>The Orange',
        name:'Development',
        category:'development'
    },
]

export const portfolioImage = [portfolio1,portfolio2,portfolio3,portfolio4,portfolio5,portfolio6,portfolio7,portfolio8,portfolio9,portfolio10,portfolio11,portfolio12]

export const portfolioData2 = [
    {
        id:1,
        image:portfolio1,
        title:'The Leaf and Letter',
        name:'Branding',
        category:'branding'
    },
    {
        id:2,
        image:portfolio13,
        title:'The Papers',
        name:'Designing',
        category:'designing'
    },
    {
        id:3,
        image:portfolio3,
        title:'The Headphones',
        name:'Photography',
        category:'photography'
    },
    {
        id:4,
        image:portfolio14,
        title:'The Projects',
        name:'Development',
        category:'development'
    },
    {
        id:5,
        image:portfolio2,
        title:'The Cup of Coffee',
        name:'Branding',
        category:'branding'
    },
    {
        id:6,
        image:portfolio15,
        title:'The Pen and Books',
        name:'Designing',
        category:'designing'
    },
    {
        id:7,
        image:portfolio17,
        title:'The Strawberry',
        name:'Photography',
        category:'photography'
    },
    {
        id:8,
        image:portfolio18,
        title:'The Leaf and Books',
        name:'Development',
        category:'development'
    },
    {
        id:9,
        image:portfolio16,
        title:'The Cactus Plant',
        name:'Branding',
        category:'branding'
    },
    {
        id:10,
        image:portfolio10,
        title:'The Yellow Bag',
        name:'Photography',
        category:'photography'
    },
    {
        id:11,
        image:portfolio9,
        title:'The Teapot Table',
        name:'Designing',
        category:'designing'
    },
    {
        id:12,
        image:portfolio11,
        title:'>The Orange',
        name:'Development',
        category:'development'
    },
]

export const portfolioImage2 = [portfolio1,portfolio13,portfolio3,portfolio14,portfolio2,portfolio15,portfolio17,portfolio18,portfolio16,portfolio10,portfolio9,portfolio11]