import React from "react";
import { Link } from "react-router-dom";
import bg1 from "../assets/images/web/services/1920x350.jpg"
import about from "../assets/images/web/services/it_consulting_main.jpg"
import image1 from "../assets/images/web/services/service_sidebar.jpg"
import NavbarTwo from "../components/navbar/navbarTwo";
import FooterSeven from "../components/footer/footerSeven";
import ScrollTop from "../components/scrollTop";
import {AiOutlineCheckCircle} from "../assets/icons/vander"

export default function SingleService(){
    return(
        <>
        <NavbarTwo navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light"/>

        {/* <section className="bg-half-80 d-table bg-primary bg-gradient w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}> */}
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row g-0 align-items-center mt-5">
                    <div className="col-lg-8 col-md-6">
                        <div className="title-heading text-md-start text-center">
                            <h5 className="heading fw-semibold page-heading text-white title-dark">IT Consulting</h5>
                            <p className="text-white-50 mb-0">Walking is the best possible exercise</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 mt-sm-0">
                        <div className="text-md-end text-center">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/services">Services</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">IT Consulting</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       

        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-6">
                        <div className="section-title">
                            <img src={about} className="img-fluid rounded" alt=""/>

                            {/* <h4 className="mt-4">Preparing for your success with web development</h4> */}

                            <p className="text-muted mt-4">At Integra Assist, we offer adept IT consulting services fortified by robust IT Support & Infrastructure, providing comprehensive enterprise solutions. Our services facilitate a seamless transition for our clients, enabling them to harness innovative technology, transform with assurance, and optimize organizational processes to meet forthcoming challenges, all while enhancing the bottom line.</p>

                            <p className="text-muted mt-4">Our profound understanding of business processes, coupled with rich industry knowledge, allows us to craft pragmatic IT solutions tailored to address the pivotal challenges our clients face. Our skilled consultants design, define, propose, and implement IT strategies with a focus on reducing operational costs, propelling growth, augmenting the bottom line, and unlocking new revenue avenues.</p>

                            <p className="text-muted mt-4">Unlike many IT consulting firms, we prioritize understanding our client’s unique constraints and requirements from their perspective, ensuring a more personalized service. Our distinctive approach in delivering IT consulting services aims at transforming your technological landscape, providing you with a competitive advantage. With collective insight, real-world experience, and proof-of-concept testing conducted in our centers, we are well-equipped to support a diverse range of customer implementations.</p>

                            
                        
                            {/* <blockquote className="text-center mx-auto blockquote"><i className="mdi mdi-format-quote-open mdi-48px text-muted opacity-2 d-block"></i> The man who comes back through the door in the wall will never be quite the same as the man who went out. <small className="d-block text-muted mt-2">- integra Template</small></blockquote>
                             */}
                            <h5 className="mt-4">We pride ourselves in our proven solutions, tailored to: </h5>

                            <ul className="list-unstyled text-muted mb-0">
                                <li className="mb-0"><span className="text-primary h5 me-2"><AiOutlineCheckCircle className="align-middle"/></span>Devise a bespoke strategy addressing your distinct business needs</li>
                                <li className="mb-0"><span className="text-primary h5 me-2"><AiOutlineCheckCircle className="align-middle"/></span>Evaluate your IT infrastructure for potential enhancements</li>
                                <li className="mb-0"><span className="text-primary h5 me-2"><AiOutlineCheckCircle className="align-middle"/></span>Demystify the implications new technologies may have on your business through interactive workshops</li>
                                <li className="mb-0"><span className="text-primary h5 me-2"><AiOutlineCheckCircle className="align-middle"/></span>Implement state-of-the-art IT solutions to foster growth</li>
                            </ul>

                            <p className="text-muted mt-4">Our accessible and reliable consultants stand ready to provide you with a competitive edge, leveraging extensive experience to design engagement and consumption models suitable for businesses of varying sizes. Trust Integra Assist to be your partner in navigating the IT domain, ensuring a journey marked by growth, efficiency, and innovation.</p>

                           
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="sidebar sticky-bar">
                            <div className="widget text-center position-relative">
                                <div className="rounded shadow bg-white">
                                    <div className="position-relative">
                                        <img src={image1} className="img-fluid rounded shadow" alt=""/>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="widget mt-4 pt-2">
                          
                                <div className="card bg-white shadow rounded border-0">
                                    <div className="card-body">
                                        <h4 className="card-title">Get In Touch !</h4>
                                        <div className="custom-form mt-4">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                                            <input name="name" id="name" type="text" className="form-control" placeholder="Name :"/>
                                                        </div>
                                                    </div>
                
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                            <input name="email" id="email" type="email" className="form-control" placeholder="Email :"/>
                                                        </div> 
                                                    </div>
                
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Subject</label>
                                                            <input name="subject" id="subject" className="form-control" placeholder="Subject :"/>
                                                        </div>
                                                    </div>
                
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Comments <span className="text-danger">*</span></label>
                                                            <textarea name="comments" id="comments" rows="3" className="form-control" placeholder="Message :"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button className="btn btn-primary">Send Message</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterSeven/>
        <ScrollTop/>
        </>
    )
}